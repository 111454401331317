module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-pointer-events/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon_source.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ca382591e6ee57c2cda28c2f8d644c66"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
